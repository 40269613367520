<template>
    <page title="Tarefas">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div class="mt-2" v-if="totalCount > 0">
            <span class="subtitle-2 font-weight-regular">{{totalCount}} resultados encontrados</span>
        </div>

        <template>
            <v-timeline align-top class="custom-timeline">
                <template v-for="(tarefa, index) in tarefas">
                    <v-timeline-item :key="tarefa.id" right small
                                     :icon="getTimelineIcon(tarefa)"
                                     :color="getTimelineColor(tarefa)">
                        <template v-slot:opposite>
                            <p class="mb-0 body-1" style="line-height: 14px">
                                {{tarefa.inicio | moment('DD MMM')}}
                            </p>
                            <p class="mb-0 caption grey--text" style="line-height: 12px">
                                {{tarefa.inicio | moment('HH:mm:ss')}}
                            </p>
                        </template>
                        <v-card :color="getCardColor(tarefa)">
                            <v-card-title :class="(!tarefa.concluidoComSucesso ? 'white--text ':'') + 'subtitle-1 py-2'">
                                {{parseText(tarefa.nome)}} #{{tarefa.id}}
                                <v-spacer></v-spacer>
                                <v-btn icon v-if="tarefa.fim && !tarefa.concluidoComSucesso"><v-icon @click="apagarTarefa(tarefa, index)" color="white">mdi-delete</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text class="pb-1 pt-2 white">
                                <v-row no-gutters class="mb-2">
                                    <template v-if="tarefa.fim">
                                        <div style="width: 100%">
                                            <template v-if="tarefa.concluidoComSucesso">
                                                <v-row no-gutters style="width: 100%" class="mb-1">
                                                    <template v-if="tarefa.tipo === 'import-operacoes'">
                                                        <v-col>
                                                            <span class="green--text font-weight-medium">{{tarefa.resultadoTotalOperacoesAdicionadas}} operações inseridas</span>
                                                        </v-col>
                                                    </template>
                                                    <template v-else-if="tarefa.tipo === 'importa-arquivos'">
                                                        <v-col>
                                                            <span class="green--text font-weight-medium">{{tarefa.resultadoTotalFiscalizacoesComArquivos}} com arquivos</span>
                                                        </v-col>
                                                        <v-col>
                                                            <span class="orange--text font-weight-medium">{{tarefa.resultadoTotalFiscalizacoesSemArquivos}} sem arquivos</span>
                                                        </v-col>
                                                        <v-col>
                                                            <span class="red--text font-weight-medium">{{tarefa.resultadoTotalFiscalizacoesNaoEncontradas}} não encontradas</span>
                                                        </v-col>
                                                    </template>
                                                    <template v-else-if="tarefa.tipo === 'envia-laudos-banco'">
                                                        <v-col>
                                                            <span class="green--text font-weight-medium">{{tarefa.resultadoTotalFiscalizacoesEnviadas}} enviadas</span>
                                                        </v-col>
                                                        <v-col>
                                                            <span class="orange--text font-weight-medium">{{tarefa.resultadoTotalFiscalizacoesNaoEnviadas}} não enviadas</span>
                                                        </v-col>
                                                        <v-col>
                                                            <span class="red--text font-weight-medium">{{tarefa.resultadoTotalFiscalizacoesNaoEncontradas}} não encontradas</span>
                                                        </v-col>
                                                    </template>
                                                    <template v-if="tarefa.tipo === 'baixa-extrato-bancario'">
                                                        <v-col>
                                                            <span class="green--text font-weight-medium">{{tarefa.resultadoTotalTransacoesAdicionadas}} transações inseridas</span>
                                                        </v-col>
                                                    </template>
                                                </v-row>
                                            </template>
                                            <template v-else>
                                                <span class="red--text font-weight-medium">Erro ao processar tarefa</span>
                                            </template>
                                        </div>

                                        <v-btn class="mr-2" color="green darken-1" dark x-small rounded @click="showResumoDialog(tarefa)" v-if="tarefa.concluidoComSucesso">
                                            <v-icon left small>mdi-format-list-checks</v-icon>Ver Relatório
                                        </v-btn>
                                        <v-btn x-small rounded @click="showLogDialog(tarefa)">
                                            <v-icon left small>mdi-text</v-icon>Ver Log
                                        </v-btn>
                                    </template>
                                    <template v-else>
                                        <span class="font-weight-medium">Processando tarefa...</span>
                                    </template>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col class="shrink" style="white-space: nowrap">
                                        <span class="caption">Duração: {{formatDuracao(tarefa)}}</span>
                                    </v-col>
                                    <v-col class="text-right">
                                        <span class="caption">Disparado por: </span>
                                        <span class="caption" v-if="tarefa.disparadoPor">{{tarefa.disparadoPor.nome}}</span>
                                        <span class="caption" v-else>Automático</span>
                                    </v-col>
                                </v-row>

                            </v-card-text>

                        </v-card>
                    </v-timeline-item>
                </template>
                <div v-if="totalCount != tarefas.length" v-intersect="infiniteScroll" class="text-center" style="height: 30px">
                    <v-progress-circular size="20" width="2" indeterminate color="primary"></v-progress-circular>
                </div>
            </v-timeline>
        </template>
        <tarefa-log-dialog ref="tarefaLogDialog" />
        <importar-arquivos-relatorio-dialog ref="importarArquivosRelatorioDialog" />
        <envia-laudos-banco-relatorio-dialog ref="enviarLaudosBancoRelatorioDialog" />
        <import-operacoes-relatorio-dialog ref="importOperacoesRelatorioDialog" />
        <baixa-extrato-bancario-relatorio-dialog ref="baixaExtratoBancarioRelatorioDialog" />
        <processa-coordenadas-relatorio-dialog ref="processaCoordenadasRelatorioDialog" />
    </page>
</template>

<script>
// @ts-ignore
import page from '../../components/Page';
import gql from "graphql-tag";
import {getIconByFiscalizacaoTipo} from "../../assets/FiscaizacaoUtil";
import EnviaLaudosBancoRelatorioDialog from "./EnviaLaudosBancoRelatorioDialog";
import ImportarArquivosRelatorioDialog from "./ImportarArquivosRelatorioDialog";
import TarefaLogDialog from "./TarefaLogDialog";
import ImportOperacoesRelatorioDialog from "./ImportOperacaoesRelatorioDialog";
import BaixaExtratoBancarioRelatorioDialog from "@/views/sistema/BaixaExtratoBancarioRelatorioDialog";
import ProcessaCoordenadasRelatorioDialog from "@/views/sistema/ProcessaCoordenadasRelatorioDialog";
export default {
    name: "TarefasList",
    components: {
        ProcessaCoordenadasRelatorioDialog,
        BaixaExtratoBancarioRelatorioDialog,
        ImportOperacoesRelatorioDialog,
        page,
        EnviaLaudosBancoRelatorioDialog,
        ImportarArquivosRelatorioDialog,
        TarefaLogDialog
    },
    data(){
        return {
            totalCount: 0,
            tarefas: [],
            loading: true,
            lastId: null,
        }
    },
    apollo:{
        $subscribe:{
            tarefaAtualizada:{
                query: gql`subscription {
                    tarefa:tarefaLogPublisher{
                        id
                        nome
                        tipo
                        inicio
                        fim
                        concluidoComSucesso
                        resultadoTotalOperacoesAdicionadas
                        resultadoTotalFiscalizacoesComArquivos
                        resultadoTotalFiscalizacoesSemArquivos
                        resultadoTotalFiscalizacoesNaoEncontradas
                        resultadoTotalFiscalizacoesEnviadas
                        resultadoTotalFiscalizacoesNaoEnviadas
                        resultadoTotalTransacoesAdicionadas
                        disparadoPor{
                            nome
                        }
                        parent{
                            id
                        }
                    }
                }`,
                result ({ data }) {
                    let tarefa = data.tarefa;
                    let tarefaEncontradaIndex = this.tarefas.findIndex((t) => { return t.id === tarefa.id});
                    if(tarefaEncontradaIndex > -1){
                        this.tarefas[tarefaEncontradaIndex] = this._.cloneDeep(tarefa)
                    }else{
                        tarefa.fim = null;
                        this.tarefas.unshift(tarefa)
                    }
                },
            }
        }
    },
    mounted() {
        document.title = "Log de Tarefas - JF Assessoria Rural"
        this.getList(null)
    },
    methods:{
        getList(beforeId){
            this.loading = true;
            this.$apollo.query({
                query: gql`query($beforeId:Int) {
                    tarefaLogs(beforeId: $beforeId){
                        totalCount
                        itens{
                            id
                            nome
                            tipo
                            inicio
                            fim
                            concluidoComSucesso
                            resultadoTotalOperacoesAdicionadas
                            resultadoTotalFiscalizacoesComArquivos
                            resultadoTotalFiscalizacoesSemArquivos
                            resultadoTotalFiscalizacoesNaoEncontradas
                            resultadoTotalFiscalizacoesEnviadas
                            resultadoTotalFiscalizacoesNaoEnviadas
                            resultadoTotalTransacoesAdicionadas
                            disparadoPor{
                                nome
                            }
                            parent{
                                id
                            }
                        }
                    }
                }`,
                variables: {
                    beforeId: beforeId
                }
            }).then((result) => {
                let tarefas = result.data.tarefaLogs.itens.map((tarefa)=>{
                    tarefa.mostraDetalhes = false
                    tarefa.fiscalizacoes = null
                    tarefa.log = null;
                    return tarefa;
                });
                this.tarefas = this.tarefas.concat(tarefas)

                this.totalCount = result.data.tarefaLogs.totalCount
                this.lastId = this.tarefas[this.tarefas.length - 1].id;
            }).finally(()=>{
                this.loading = false;
            })
        },
        infiniteScroll(entries, observer, isIntersecting){
            if(isIntersecting && !this.loading){
                this.getList(this.lastId)
            }
        },
        formatDuracao(tarefa){
            let formatInt = (int) => {
                if (int < 10) {
                    return `0${int}`;
                }
                return `${int}`;
            };

            let formatDuration = (time) => {
                const seconds = this.$moment.duration(time).seconds();
                const minutes = this.$moment.duration(time).minutes();
                const hours = this.$moment.duration(time).hours();
                if (hours > 0) {
                    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)} horas`;
                }
                if (minutes > 0) {
                    return `${formatInt(minutes)}:${formatInt(seconds)} min`;
                }
                return `${formatInt(seconds)} seg`;
            };
            if(tarefa.fim){
                return formatDuration(this.$moment.duration(this.$moment(tarefa.fim).diff(this.$moment(tarefa.inicio))))
            }
            return null;
        },
        getItemIcon(tipo){
            return getIconByFiscalizacaoTipo(tipo);
        },
        apagarTarefa(tarefa, index){
            this.$dialog.confirm({
                title: 'Atenção',
                text: 'Deseja realmente apagar essa tarefa?'
            }).then((result) => {
                if (result) {
                    this.$apollo.mutate({
                        mutation: gql`mutation($id:Int!) {
                            apagarTarefa(tarefaLogId: $id)
                        }`,
                        variables:{
                            id: tarefa.id
                        }
                    }).then((result) => {
                        this.tarefas.splice(index, 1)
                    });
                }
            })
        },
        showLogDialog(tarefa){
            this.$refs.tarefaLogDialog.openDialog(tarefa.id);
        },
        showResumoDialog(tarefa){
            console.log("tarefa", tarefa)
            switch (tarefa.tipo) {
                case "importa-arquivos":
                    this.$refs.importarArquivosRelatorioDialog.openDialog(tarefa.id);
                    break;
                case "envia-laudos-banco":
                    this.$refs.enviarLaudosBancoRelatorioDialog.openDialog(tarefa.id);
                    break;
                case "import-operacoes":
                    this.$refs.importOperacoesRelatorioDialog.openDialog(tarefa.id);
                    break;
                case "baixa-extrato-bancario":
                    this.$refs.baixaExtratoBancarioRelatorioDialog.openDialog(tarefa.id);
                    break;
                case "processa-coordenadas":
                    this.$refs.processaCoordenadasRelatorioDialog.openDialog(tarefa.id);
                    break;
            }
        },
        parseText(nome){
            return nome
        },
        getTimelineIcon(tarefaLog){
            if(!tarefaLog.fim){
                return "mdi-progress-clock"
            }else{
                return tarefaLog.concluidoComSucesso ? 'mdi-check' : 'mdi-close'
            }

        },
        getTimelineColor(tarefaLog){
            if(!tarefaLog.fim){
                return "yellow darken-2"
            }else{
                return tarefaLog.concluidoComSucesso ? 'green' : 'red'
            }

        },
        getCardColor(tarefaLog){
            if(!tarefaLog.fim){
                return "yellow darken-2"
            }else{
                return tarefaLog.concluidoComSucesso ? 'grey lighten-4' : 'red'
            }
        }
    }
}
</script>

<style >
    .custom-timeline::before{
        /*background: red !important;*/
        left: 96px !important;
    }
    .custom-timeline .v-timeline-item{
        justify-content: flex-end;
    }

    .custom-timeline .v-timeline-item__body{
        max-width: 500px !important;
    }
    .custom-timeline .v-timeline-item__opposite{
        max-width: max-content;
        align-self: unset;
    }
    .custom-timeline .v-expansion-panel-content__wrap{
        padding: unset !important;
    }
    @media(max-width: 599px) {
        .custom-timeline .v-timeline-item__divider {
            min-width: 38px;
        }
        .custom-timeline .v-timeline::before{
            /*background: red !important;*/
            left: 67px !important;
        }
        .custom-timeline .v-timeline-item__body{
            margin-left: 8px;
        }
    }
</style>